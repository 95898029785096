// import moment from "moment";
import DateBox from "../controls/DateBox";
import SelectBox from "../controls/SelectBox";
import TextBox from "../controls/TextBox";
import MultiSelectBox from "../controls/MultiSelectBox";
import AutoComplete from "../common/AutoComplete";
import CheckBox from "../controls/CheckBox";
import RadioBox from "../controls/RadioBox";
import TextAreaBox from "../controls/TextAreaBox";
import CheckBoxGroup from "../controls/CheckBoxGroup";
import FormLabel from "../controls/FormLabel";
import moment from "moment";

const RenderField = ({
  formField,
  webFormFields,
  DropDowns,
  setWebFormFields,
  webForm,
  handleChange,
  handleCheckBoxGroup,
  handleAchievementsChange,
  handleAgent,
  handleSuburb,
}) => {
  let tempWebFormFields = webFormFields;

  const {
    fieldType,
    fieldLabel,
    fieldName,
    error,
    isMandatory,
    extraLabel,
    staticData,
  } = formField;

  let dependentOn = true;

  if (
    fieldName === "IELTSTestLocation" ||
    fieldName === "IELTSTestScore" ||
    fieldName === "IELTSTestDate"
  ) {
    // dependentOn =
    // tempWebFormFields["IELTSTestCurrent"] &&
    // tempWebFormFields["EnglishFirstLanguage"];
    dependentOn =
    tempWebFormFields["IELTSTestCurrent"];
  } else if (
    fieldName === "EnglishPriorCourseProvider" ||
    fieldName === "EnglishPriorCourseName"
  ) {
    dependentOn = tempWebFormFields["EnglishPriorCourse"];
  } else if (
    fieldName === "EnglishAssessmentTestMethod" ||
    fieldName === "EnglishAssessmentTestScore" ||
    fieldName === "EnglishAssessmentTestDate"
  ) {
    dependentOn = tempWebFormFields["EnglishAssessmentTestCompleted"];
  } else if (
    fieldName === "HomeStayRequestType" ||
    fieldName === "HomeStayRequestDuration"
  ) {
    dependentOn = tempWebFormFields["HomeStayRequested"];
  } else if (fieldName === "NameOSHCInsurer" || fieldName === "MemberNumber") {
    dependentOn = tempWebFormFields["OSHCArranged"];
  } else if (
    fieldName === "NameOSHCCoverDates" ||
    fieldName === "NameOSHCCoverType"
  ) {
    dependentOn = !tempWebFormFields["OSHCArranged"];
  } else if (
    fieldName === "OSHCProvider" ||
    fieldName === "OSHC_MembershipNo" ||
    fieldName === "CoverStartDate" ||
    fieldName === "CoverEndDate" ||
    fieldName === "OSHC_CoverType"
  ) {
    dependentOn = !tempWebFormFields["RequireOSHC"];
  }

  // Comment - Code for agent not in list
  else if (fieldName === "NewAgentCode" || fieldName === "NewAgentName") {
    dependentOn = tempWebFormFields["AgentNotInList"]
  }
  else if (fieldName === "AgentNotInList") {
      dependentOn = tempWebFormFields["ReferredByAgent"]
  }


  switch (fieldType) {
    case 1:
      if (fieldName === "UniqueStudentIdentifier") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          dependentOn = tempWebFormFields["USIPermission"];
        } else {
          dependentOn =
            tempWebFormFields["USIPermission"] &&
            tempWebFormFields["AlreadyHaveUSI"] &&
            JSON.parse(tempWebFormFields["USIPermission"]) === 1 &&
            JSON.parse(tempWebFormFields["AlreadyHaveUSI"]) === 1;
        }
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName === "LastSchoolYear") {
        let schoolLevel = tempWebFormFields["SchoolLevelCompleted"];
        const schoolLevelDD = DropDowns["SchoolLevelCompleted"];
        if (
          tempWebFormFields["SchoolLevelCompleted"] &&
          tempWebFormFields["SchoolLevelCompleted"] > 0
        ) {
          const selectedSLD = schoolLevelDD.filter(
            (sld) =>
              sld.avetCodeID === tempWebFormFields["SchoolLevelCompleted"]
          );
          if (
            selectedSLD[0].avetCode === "02" ||
            selectedSLD[0].avetCode === "@@"
          ) {
            formField.isMandatory = false;
            formField.error = false;
          } else {
            formField.isMandatory = true;
            formField.error = false;
          }
        } else {
          formField.isMandatory = false;
          formField.error = false;
        }
      } else if (fieldName === "DIBPCity" || fieldName === "DIBPCountry") {
        if (
          tempWebFormFields["VisaType"] &&
          ((parseInt(tempWebFormFields["VisaType"]) >= 1 &&
            parseInt(tempWebFormFields["VisaType"]) <= 7) ||
            parseInt(tempWebFormFields["VisaType"]) === 18 ||
            parseInt(tempWebFormFields["VisaType"]) === 25)
        ) {
        } else {
          dependentOn = false;
        }
      } else if (fieldName === "PriorVisaRefusalNotes") {
        dependentOn = tempWebFormFields["PriorVisaRefusal"];
      } else if (fieldName === "TownOfBirth") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          dependentOn = tempWebFormFields["CollegeSetUpUSI"];
        } else {
          dependentOn =
            tempWebFormFields["CollegeSetUpUSI"] &&
            tempWebFormFields["AlreadyHaveUSI"] &&
            JSON.parse(tempWebFormFields["CollegeSetUpUSI"]) === 1 &&
            JSON.parse(tempWebFormFields["AlreadyHaveUSI"]) === 2;
        }
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName === "EATCName") {
        dependentOn = tempWebFormFields["IsCompletedEATC"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName === "TaxFileNo") {
        dependentOn = false;
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName === "PriorLearningSubjectReason") {
        dependentOn = tempWebFormFields["PriorLearningSubjectOpt"] === 1
      }

      return (
        <TextBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 2:
      if (fieldName === "TypeOfApprenticeship") {
        dependentOn = tempWebFormFields["RegisterinApprenticeship"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName === "AvetmissSchoolType") {
        dependentOn = tempWebFormFields["AtSchool"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      } else if (fieldName === "BirthCountry") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          dependentOn = tempWebFormFields["CollegeSetUpUSI"];
        } else {
          dependentOn =
            tempWebFormFields["CollegeSetUpUSI"] &&
            tempWebFormFields["AlreadyHaveUSI"] &&
            JSON.parse(tempWebFormFields["CollegeSetUpUSI"]) === 1 &&
            JSON.parse(tempWebFormFields["AlreadyHaveUSI"]) === 2;
        }
        formField.isMandatory = dependentOn;
        formField.error = false;
      }
      return (
        <SelectBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
          data={DropDowns[fieldName]}
        />
      );
      break;
    case 3:
      var minDate = undefined;
      if (
        fieldName === "VisaExpiryDate" ||
        fieldName === "PassportExpiryDate"
      ) {
        // minDate = moment().add(0, "years").toDate();
      }
      return fieldName === "DateOfBirth" ? (
        <DateBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          maxDate={moment()
            .add(-1 * webForm.minAgeEligibility, "years")
            .toDate()}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
        />
      ) : (
        <DateBox
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          minDate={minDate}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 4:
      return (
        <TextBox
          name={fieldName}
          dependentOn={dependentOn}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 5:
      return (
        <TextBox
          name={fieldName}
          dependentOn={dependentOn}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 6:
      if (fieldName === "Disability") {
        dependentOn =
          tempWebFormFields["HasDisability"] &&
          JSON.parse(tempWebFormFields["HasDisability"]) === true;
        formField.isMandatory = dependentOn;
        formField.error = false;
      }

      return (
        <MultiSelectBox
          name={fieldName}
          dependentOn={dependentOn}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
          data={DropDowns[fieldName]}
        />
      );
      break;
    case 7:
      if (fieldName.indexOf("Suburb") > 0) {
        if (fieldName !== "IntrnationalSuburbID") {
          return (
            <>
              <AutoComplete
                name={fieldName}
                error={error}
                label={fieldLabel + (isMandatory ? "*" : "")}
                value={tempWebFormFields[fieldName]}
                onChange={handleSuburb}
                selectedItem={
                  webFormFields["selected" + fieldName] &&
                  webFormFields["selected" + fieldName]
                }
              />
              <TextBox
                dependentOn={dependentOn}
                readOnly={true}
                name={fieldName + "State"}
                error={error}
                label={"State"}
                value={tempWebFormFields[fieldName + "State"]}
              />
              <TextBox
                dependentOn={dependentOn}
                readOnly={true}
                name={fieldName + "PostCode"}
                error={error}
                label={"Post Code"}
                value={tempWebFormFields[fieldName + "PostCode"]}
              />
              <TextBox
                dependentOn={dependentOn}
                readOnly={true}
                name={fieldName + "Country"}
                error={error}
                label={"Country"}
                value={tempWebFormFields[fieldName + "Country"]}
              />
            </>
          );
        }
      } else {
        if (fieldName === "AgentId") {
          dependentOn = tempWebFormFields["ReferredByAgent"] || false;
        }
        return (
          <AutoComplete
            name={fieldName}
            error={error}
            endPoint="Agents/List"
            dependentOn={dependentOn}
            label={fieldLabel + (isMandatory ? "*" : "")}
            value={tempWebFormFields[fieldName]}
            onChange={handleAgent}
          />
        );
      }
      break;
    case 8:
      var tempVal = false;

      if (fieldName === "PriorVisaRefusal") {
        if (
          tempWebFormFields["VisaType"] &&
          ((parseInt(tempWebFormFields["VisaType"]) >= 1 &&
            parseInt(tempWebFormFields["VisaType"]) <= 7) ||
            parseInt(tempWebFormFields["VisaType"]) === 18 ||
            parseInt(tempWebFormFields["VisaType"]) === 25)
        ) {
        } else {
          dependentOn = false;
        }
      } else if (fieldName === "IELTSTestCurrent") {
        // dependentOn = tempWebFormFields["EnglishFirstLanguage"];
      } else if (fieldName === "USIPermission") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          tempVal = true;
        } else {
          tempVal =
            tempWebFormFields["AlreadyHaveUSI"] &&
            JSON.parse(tempWebFormFields["AlreadyHaveUSI"]) === 1;
        }
        dependentOn = tempVal;
      } else if (fieldName === "CollegeSetUpUSI") {
        if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
          tempVal = true;
          dependentOn = tempVal;
        } else {
          tempVal =
            tempWebFormFields["AlreadyHaveUSI"] &&
            JSON.parse(tempWebFormFields["AlreadyHaveUSI"]) === 2;
          dependentOn = tempVal;
        }
      }

      return (
        <CheckBox
          dependentOn={dependentOn}
          hintText={extraLabel}
          error={error}
          label={fieldLabel}
          name={fieldName}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 9:
      if (fieldName === "OnshoreOffShoreStatus") {
        dependentOn = tempWebFormFields["OverseasStudent"];
      } else if (fieldName === "Medical_Flag") {
        dependentOn =
          tempWebFormFields["HasDisability"] &&
          JSON.parse(tempWebFormFields["HasDisability"]) === true;
      }

      return (
        <RadioBox
          dependentOn={dependentOn}
          error={error}
          value={tempWebFormFields[fieldName]}
          name={fieldName}
          label={fieldLabel}
          onChange={handleChange}
          dataStr={formField.staticData}
          size={8}
        />
      );
      break;
    case 10:
      const data = DropDowns[fieldName];
      const staticData = [
        { idField: "A", valueField: "A - Australian" },
        { idField: "E", valueField: "E - Australian Equivalent" },
        { idField: "I", valueField: "I - International" },
      ];
      if (fieldName === "PriorAchievments") {
        dependentOn = tempWebFormFields["HasPriorAchievments"];
        formField.isMandatory = dependentOn;
        formField.error = false;
      }

      return (
        <div className={!dependentOn ? " hidden" : ""}>
          <FormLabel
            key={fieldLabel + 1}
            text={fieldLabel}
            color={error ? "red" : "black"}
            error={error}
          >
            <div className="achievement-pop-container">
              <div>
                {data &&
                  data.map((ach, idx) => (
                    <div
                      key={`dropDown-${idx}`}
                      className="achievement-pop-row"
                    >
                      <CheckBox
                        dependentOn={true}
                        name="chkachievement"
                        error={error}
                        label={ach["valueField"]}
                        value={ach.chkachievement}
                        onChange={(event) => {
                          handleAchievementsChange(event, ach);
                        }}
                      />
                      <SelectBox
                        dependentOn={dependentOn}
                        name="selachievement"
                        label={" "}
                        value={ach.selachievement}
                        onChange={(event, name) =>
                          handleAchievementsChange(
                            {
                              target: {
                                type: "select-one",
                                value: event,
                                name: name,
                              },
                            },
                            ach
                          )
                        }
                        data={staticData}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </FormLabel>
        </div>
      );
      break;
    case 11:
      return (
        <TextAreaBox
          rows="3"
          dependentOn={dependentOn}
          name={fieldName}
          error={error}
          label={fieldLabel + (isMandatory ? "*" : "")}
          value={tempWebFormFields[fieldName]}
          onChange={handleChange}
        />
      );
      break;
    case 12:
      let currentFieldValue = tempWebFormFields[formField.fieldName];
      let chGroupData = [];
      const sData = formField.staticData;
      if (sData !== "") {
        const dataArr = sData.split(",");
        dataArr.forEach((dt) => {
          let tempArray = dt.split("|");
          let dataObj = {};
          dataObj.idField = tempArray[0];
          dataObj.valueField = tempArray[1];
          dataObj.checked =
            ("," + tempWebFormFields[fieldName] + ",").indexOf(
              "," + tempArray[0] + ","
            ) >= 0
              ? true
              : false;
          if (formField.maxSelectionCount > 0)
            dataObj.disabled =
              !dataObj.checked &&
              currentFieldValue &&
              currentFieldValue.split(",")?.length >=
                formField.maxSelectionCount
                ? true
                : false;
          else dataObj.disabled = false;
          chGroupData.push(dataObj);
        });
      }

      return (
        <CheckBoxGroup
          error={formField.error}
          dependentOn={dependentOn}
          name={fieldName}
          label={fieldLabel}
          onChange={(event) => handleCheckBoxGroup(event, chGroupData)}
          data={chGroupData}
        />
      );
      break;
    default:
      return "";
  }
};

export default RenderField;
